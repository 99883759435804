<template>
    <div>
        <div class="padding">
            <el-page-header @back="goBack" :content="$t('成绩单分析')">
            </el-page-header>
        </div>
        <el-card class="margin-t" style="max-width: 1000px; margin:0 auto;">

            <div class="padding margin-t">



                <div class="flex margin-t">
                    <div class="flex1 flex flex-v-center">
                        <el-button size="small" type="primary" round icon="el-icon-edit"
                            @click="$refs.goalDialog.show()">
                            {{ $t('学习目标') }}
                        </el-button>
                    </div>

                    <div class="flex1 flex flex-v-center" v-for="(item, index) in ['口语', '写作', '阅读', '听力', '总分']"
                        :key="index">
                        <span>{{ item }}：</span>
                        <span>{{ exam_data[index] }}</span>
                    </div>
                </div>

                <div class="margin-t">
                    <div class="flex flex-h-center">
                        <el-divider>
                            <div class="bold center mt">{{ $t('录入成绩') }}</div>
                        </el-divider>
                    </div>
                    <el-form :inline="true" ref="form" label-position="top" :model="form">
                        <div class="flex">
                            <div class="flex1">
                                <el-form-item label="时间"
                                    :rules="[{ required: true, message: '请选择日期', trigger: 'blur' }]" prop="day">
                                    <el-date-picker value-format="yyyy-MM-dd" style="width: inherit;" v-model="form.day"
                                        type="date" placeholder="选择日期">
                                    </el-date-picker>
                                </el-form-item>
                            </div>

                            <div class="flex1">
                                <el-form-item label="口语" prop="s" :rules="[{ validator: checkInput, trigger: 'blur' }]">
                                    <el-input v-model="form.s" placeholder="口语分数"></el-input>
                                </el-form-item>
                            </div>

                            <div class="flex1">
                                <el-form-item label="写作" prop="w" :rules="[{ validator: checkInput, trigger: 'blur' }]">
                                    <el-input v-model="form.w" placeholder="写作分数"></el-input>
                                </el-form-item>
                            </div>
                            <div class="flex1">
                                <el-form-item label="阅读" prop="r" :rules="[{ validator: checkInput, trigger: 'blur' }]">
                                    <el-input v-model="form.r" placeholder="阅读分数"></el-input>
                                </el-form-item>
                            </div>
                            <div class="flex1">
                                <el-form-item label="听力" prop="l" :rules="[{ validator: checkInput, trigger: 'blur' }]">
                                    <el-input v-model="form.l" placeholder="听力分数"></el-input>
                                </el-form-item>
                            </div>

                            <div class="flex1">
                                <el-form-item label="总分" prop="t" :rules="[{ validator: checkInput, trigger: 'blur' }]">
                                    <el-input :max="90" :min="10" v-model="form.t" placeholder="总分分数"></el-input>
                                </el-form-item>
                            </div>
                            <!-- 
                            <div class="flex flex-h-end">
                                <el-form-item label="⁡ ">
                                    <el-button type="primary" @click="submit">提交</el-button>
                                </el-form-item>
                            </div> -->

                        </div>


                    </el-form>
                </div>

            </div>
            <el-table :show-header="false" :data="tableData" style="width: 100%">
                <el-table-column prop="label">

                </el-table-column>

                <el-table-column prop="day" label="">
                    <template slot-scope="scope">
                        <el-slider v-model="scope.row.value"></el-slider>
                    </template>
                </el-table-column>

                <el-table-column prop="value" width="100px">

                </el-table-column>
            </el-table>

            <div class="flex flex-h-center padding margin-t">
                <el-button type="primary" round icon="el-icon-document-checked" @click="submit">{{ $t('提交分析')
                    }}</el-button>
            </div>
        </el-card>
        <goalDialog ref="goalDialog"></goalDialog>
        <transcript-dialog ref="transcriptDialog"></transcript-dialog>
    </div>
</template>

<script>

import goalDialog from '../../components/GoalDialog.vue';
import transcriptDialog from '../../components/TranscriptDialog.vue';


export default {
    components: {
        goalDialog, transcriptDialog
    },
    data() {
        return {
            checkInput: (rule, value, callback) => {
                if (!value) {
                    return callback(new Error('分数不能为空'));
                }
                setTimeout(() => {
                    if (isNaN(Number(value)) || value < 10 || value > 90) {
                        callback(new Error('输入10-90的整数'));
                    } else {
                        callback();
                    }
                });
            },
            form: { s: '', w: '', r: '', l: '', t: '', day: '' },
            items: [
                'Open Response Speaking and Writing',
                'Reproducing Spoken and Written Language',
                'Extended Writing',
                'Short Writing',
                'Extended Speaking',
                'Short Speaking',
                'Multiple-skills Comprehension',
                'Single-skill Comprehension'
            ],
            tableData: []
        };
    },

    computed: {
        exam_data() {
            return this.$store.state.user.exam_data || {};
        }
    },

    created() {
        if (this.$route.query.day) {
            this.form.day = this.$route.query.day
            this.form.s = this.$route.query.s
            this.form.w = this.$route.query.w
            this.form.r = this.$route.query.r
            this.form.l = this.$route.query.l
            this.form.t = this.$route.query.t
        }
        this.tableData = this.items.map(item => {
            return { label: item, value: 0 }
        })
    },

    methods: {
        goBack() {
            this.$router.back();
        },
        toPlan() {
            this.$router.replace('/main/plan')
        },
        submit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    const keys = ['s', 'w', 'r', 'l', 't']
                    for (let i = 0; i < keys.length; i++) {
                        if (isNaN(Number(this.form[keys[i]]) || this.form[keys[i]] < 0 || this.form(keys[i]) > 90)) {
                            this.$message.warn(this.$t('分数在0到90之间'))
                            return
                        }
                    }
                    this.form.items = this.tableData.map(item => {
                        return item.value
                    })

                    this.$http.post("/api/exam/score/save", this.form).then(() => {
                        this.$message({
                            message: this.$t('提交成功'),
                            type: 'success'
                        });
                    })

                    this.$refs.transcriptDialog.show(this.form)

                } else {
                    console.log('error submit!!');
                    return false;
                }
            });

        },

    },
};
</script>
<style scoped></style>
